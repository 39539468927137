import createReactContext from '@ant-design/create-react-context';
import defaultRenderEmpty from './renderEmpty';
export const ConfigContext = createReactContext({
    // We provide a default function for Context without provider
    getPrefixCls: (suffixCls, customizePrefixCls) => {
        if (customizePrefixCls)
            return customizePrefixCls;
        return `ant-${suffixCls}`;
    },
    renderEmpty: defaultRenderEmpty,
});
export const ConfigConsumer = ConfigContext.Consumer;
